export default {
    zh:{
        pc:{
            queryParam:{
                oaFlag:'是否OA下单',
                oaFlag_placeholder:'请选择是否OA下单',
                oaTime:'OA下单时间',
                oaTime_placeholder:'请选择OA下单时间',
                scjd:'生产基地',
                scjd_placeholder:'请选择生产基地',
            },
            columns:{
                F_CODE:'排产单编号',
                F_SJPCRQ:'实际排产日期',
                F_YJRKRQ:'预计入库日期',
                F_SJRKRQ:'实际入库日期',
                F_KFYRQ:'可发运日期',
                F_SCJD:'生产基地',
                F_OA_FLAG:'是否OA下单',
                F_OA_TIME:'OA下单时间',
            },
            form:{
                code:'排产单编号',
                code_placeholder:'请输入排产单编号',
                code_rule: '请输入排产单编号',
                sjpcrq:'实际排产日期',
                sjpcrq_placeholder:'请选择实际排产日期',
                sjpcrq_rule: '请选择实际排产日期',
                yjrkrq:'预计入库日期',
                yjrkrq_placeholder:'请选择预计入库日期',
                yjrkrq_rule: '请选择预计入库日期',
                sjrkrq:'实际入库日期',
                sjrkrq_placeholder:'请选择实际入库日期',
                sjrkrq_rule: '请选择实际入库日期',
                kfyrq:'可发运日期',
                kfyrq_placeholder:'请选择可发运日期',
                scjd:'生产基地',
                scjd_placeholder:'请选择生产基地',
                scjd_rule: '请选择生产基地',
                oaFlag:'是否OA下单',
                oaFlag_placeholder:'请选择是否OA下单',
                oaFlag_rule: '请选择是否OA下单',
                oaTime:'OA下单时间',
                oaTime_placeholder:'请选择OA下单时间',
            },
            PcDetail:{
                F_SPLIT_ORDER_ID:'小单ID',
                F_SPLIT_ORDER_ID_PLACEHOLDER:'请输入小单ID',
                F_SJRKSL:'实际入库数量',
            },
        }
    },
    en:{
        pc:{
            queryParam:{
                oaFlag:'是否OA下单',
                oaFlag_placeholder:'请选择是否OA下单',
                oaTime:'OA下单时间',
                oaTime_placeholder:'请选择OA下单时间',
                scjd:'生产基地',
                scjd_placeholder:'请选择生产基地',
            },
            columns:{
                F_CODE:'排产单编号',
                F_SJPCRQ:'实际排产日期',
                F_YJRKRQ:'预计入库日期',
                F_SJRKRQ:'实际入库日期',
                F_KFYRQ:'可发运日期',
                F_SCJD:'生产基地',
                F_OA_FLAG:'是否OA下单',
                F_OA_TIME:'OA下单时间',
            },
            form:{
                code:'排产单编号',
                code_placeholder:'请输入排产单编号',
                code_rule: '请输入排产单编号',
                sjpcrq:'实际排产日期',
                sjpcrq_placeholder:'请选择实际排产日期',
                sjpcrq_rule: '请选择实际排产日期',
                yjrkrq:'预计入库日期',
                yjrkrq_placeholder:'请选择预计入库日期',
                yjrkrq_rule: '请选择预计入库日期',
                sjrkrq:'实际入库日期',
                sjrkrq_placeholder:'请选择实际入库日期',
                sjrkrq_rule: '请选择实际入库日期',
                kfyrq:'可发运日期',
                kfyrq_placeholder:'请选择可发运日期',
                scjd:'生产基地',
                scjd_placeholder:'请选择生产基地',
                scjd_rule: '请选择生产基地',
                oaFlag:'是否OA下单',
                oaFlag_placeholder:'请选择是否OA下单',
                oaFlag_rule: '请选择是否OA下单',
                oaTime:'OA下单时间',
                oaTime_placeholder:'请选择OA下单时间',
            },
            PcDetail:{
                F_SPLIT_ORDER_ID:'小单ID',
                F_SPLIT_ORDER_ID_PLACEHOLDER:'请输入小单ID',
                F_SJRKSL:'实际入库数量',
            },
        }
    }
}